import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import PageSeo from "../components/crumbs/pageSeo"

export default function Home() {
  return (
    <>
      <PageSeo
        title="Minden Percben | 404"
        description="Minden amiről érdemes tudnia hazánkban és a nagyvilágban. Mert minden perc érdekes!"
        keywords="hírek, érdekességek"
        canonical="https://www.mindenpercben.hu"
        canonicalMobile = "https://m.mindenpercben.hu/"        
      />
      <LayoutSite>
          A keresett oldal nem található
      </LayoutSite>
    </>
  )
}
